<template>
  <t-modal
    :name="popName"
    :variant="`transaction`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    ref="popup"
  >
    <section class="px-8 pt-10">
      <div class="font-medium text-28px text-oCharcoal">
        {{ popTitle }}
      </div>

      <div class="p-8">
        <label class="mt-8">
          <input
            type="number"
            class="w-full h-16 px-3 text-2xl font-bold text-center text-orange-400 border rounded focus:outline-none"
            v-model.number="adjustmentBalance"
            :placeholder="getAmountPlaceholder"
            ref="adjustBalanceInput"
            :disabled="!!selectedTopupOffer.id"
          />

          <!-- <span
            class="flex items-center justify-center mt-2 text-sm font-bold text-center text-gray-800"
          >
            Current Balance:{{ currencySymbol }}{{ data.currentBalance }}
          </span> -->
        </label>
        <div
          v-if="selectedTopupOffer.id"
          class="text-center text-sm font-bold text-gray-500"
        >
          <p>
            You have applied
            <span class=" text-green-600">{{
              `${selectedTopupOffer.currencySymbol}${selectedTopupOffer.price}`
            }}</span>
            offer.<span
              class="text-red-500 cursor-pointer ml-1"
              @click="handleSelectedTopupOfferInit"
              >Reset?</span
            >
          </p>
        </div>

        <AppInput
          v-model="targetAccount"
          type="richselect"
          name="target"
          :label="
            $t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.targetAccount'
            )
          "
          :options="wallets"
          class="mt-8"
          hide-search-box
        />

        <AppInput
          v-if="getRidingUnitCostViewStatus"
          ref="ridingTimeUnitCostInput"
          v-model.number="ridingTimeUnitCost"
          type="richselect"
          name="ridingTimeUnitCost"
          :label="
            $t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.selectUnitCostPerUnit.title'
            )
          "
          :placeholder="
            $t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.selectUnitCostPerUnit.placeholder'
            )
          "
          :options="ridingTimeUnitCostOptions"
          class="mt-2"
          :required="true"
          hide-search-box
        />
        <div v-if="data.type === 'add' && topupOfferData.length > 0">
          <label class="block w-full text-sm font-bold text-gray-800">
            {{
              $t(
                'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.selectTopupOffers'
              )
            }}
          </label>
          <section
            :key="`topup-offer-${index}`"
            v-for="(item, index) in topupOfferData"
          >
            <div
              class="flex items-center justify-between rounded-lg px-3 py-2  my-2 cursor-pointer shadow-sm border border-gray-300 hover:shadow-md "
              :class="
                selectedTopupOffer.index === index
                  ? 'border-2 border-gray-800'
                  : ''
              "
              @click="handleTopupOfferItemSelected(item, index)"
            >
              <div>
                <p class="text-sm font-bold text-gray-500">
                  {{ item.riding_time_seconds / 60 }} mins ride
                </p>
                <p class="text-xs font-bold text-gray-500">
                  {{ item.validity_duration }}
                  {{
                    `${item.validity_duration_unit_display_text}${
                      item.validity_duration > 1 ? 's' : ''
                    }`
                  }}
                </p>
              </div>
              <div>
                <p class="text-xl font-bold text-green-600">
                  {{ item.currency.symbol }}{{ item.price }}
                </p>
              </div>
            </div>
          </section>
        </div>

        <label class="block w-full mt-6 text-sm font-bold text-gray-800">
          {{
            $t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.adjustedBalance'
            )
          }}
          ({{ getCurrencySymbol }})
          <input
            type="text"
            class="w-full px-2 py-2 text-gray-500 bg-gray-200 border-gray-300 rounded focus:outline-none"
            :value="getCurrentBalance"
            readonly
          />
        </label>

        <div class="mt-8 single-input">
          <label class="w-full text-sm font-bold text-gray-800">
            {{
              $t(
                'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.adjustedBalanceOf'
              )
            }}
            <input
              type="text"
              class="w-full px-2 py-2 text-gray-500 bg-gray-200 border-gray-300 rounded focus:outline-none"
              :value="`${data.fullName} (${data.phoneNumber})`"
              readonly
            />
          </label>
        </div>

        <section class="mt-6">
          <t-checkbox
            wrapped
            v-model="remarksUi"
            :label="
              $t(
                'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.addRemarks.title'
              )
            "
            :name="`adminRemarksUi`"
          />
          <div class="-mt-2 text-14px" style="margin-left: 28px;">
            <span class="text-gray-500">
              {{
                $t(
                  'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.addRemarks.info'
                )
              }}
            </span>
          </div>
        </section>

        <section v-show="remarksUi" class="mt-6">
          <t-textarea v-model="remarks" />
        </section>

        <div class="flex items-center justify-end mt-12 mb-4 space-x-4">
          <AppButton
            :variant="`secondary`"
            :text="$t('components.stepNavigation.cancel')"
            @click="$refs.popup.hide()"
          />

          <AppButton
            :variant="getSubmitButtonVariant"
            :text="getSubmitButtonText"
            :isLoading="isLoading"
            @click="submitForm"
          />
        </div>
      </div>
    </section>
  </t-modal>
</template>

<script>
import { EventBus } from '@/utils/EventBus'

import { TopupOfferConfig } from '@/config/TopupOfferConfig'
import { formatNumberWithMaxDecimals } from '@/utils/Helpers.js'
export default {
  name: 'AdjustBalancePopup',
  components: {
    // AnchorButton: () => import('@/components/form/AnchorButton.vue'),
    // TextInput: () => import('@/components/form/TextInput.vue'),
    // DisplayAvatar: () => import('@/components/ui/DisplayAvatar'),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    wallets: {
      type: Array,
      required: true,
    },
    isSubscribedRidingTimeBalance: {
      type: Boolean,
      required: true,
    },
    currencySymbol: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    popName: 'AdjustBalancePopup',
    isLoading: false,
    adjustmentBalance: null,
    targetAccount: null,
    ridingTimeUnitCost: null,
    remarks: '',
    remarksUi: false,
    topupOfferData: [],
    ridingTimeUnitCostOptions: [],
    selectedTopupOffer: {
      id: null,
      index: null,
      price: null,
      currencySymbol: null,
    },
  }),
  computed: {
    popTitle() {
      if (this.data.type === 'add') {
        return this.$t(
          'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.addBalance'
        )
      }
      if (this.data.type === 'deduct') {
        return this.$t(
          'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.deductBalance'
        )
      }
      return this.$t(
        'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.adjustBalance'
      )
    },
    getSubmitButtonText() {
      if (this.data.type === 'add') {
        if (this.isSubscribedRidingTimeBalance) {
          if (this.selectedTopupOffer.id) {
            return this.$t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.addBalance'
            )
          } else {
            return `${this.$t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.add'
            )} ${this.getCurrencySymbol}${this.getAdjustAmountForSubmission}`
          }
        } else {
          return this.$t(
            'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.addBalance'
          )
        }
      }
      if (this.data.type === 'deduct') {
        if (this.isSubscribedRidingTimeBalance) {
          if (this.selectedTopupOffer.id) {
            return this.$t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.deductBalance'
            )
          } else {
            return `${this.$t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.deduct'
            )} ${this.getCurrencySymbol}${this.getAdjustAmountForSubmission}`
          }
        } else {
          return this.$t(
            'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.deductBalance'
          )
        }
      }
      return this.$t(
        'components.riderDetailsManagement.diveIntoTab.paymentMethod.modals.adjustBalance'
      )
    },
    getSubmitButtonVariant() {
      if (this.data.type === 'add') {
        return `success`
      }
      if (this.data.type === 'deduct') {
        return `warning`
      }
      return `primary`
    },
    getSelectedWallet() {
      return this.wallets.find((wallet) => wallet.id === this.targetAccount)
    },
    getCurrencySymbol() {
      return (
        this.getSelectedWallet?.currency?.symbol || this.currencySymbol || ''
      )
    },
    getCurrentBalance() {
      const currBal = Number(this.getSelectedWallet?.balance)
      let res
      if (this.data.type === 'add') {
        res = currBal + parseFloat(this.getAdjustAmountForSubmission)
      }
      if (this.data.type === 'deduct') {
        res = currBal + parseFloat(this.getAdjustAmountForSubmission) // negative value from getAdjustAmountForSubmission
      }

      return res ? res.toFixed(2) : currBal
    },
    getRidingUnitCostViewStatus() {
      if (
        this.isSubscribedRidingTimeBalance === true &&
        !this.selectedTopupOffer.id
      ) {
        return true
      } else {
        if (
          this.isSubscribedRidingTimeBalance === true &&
          !!this.selectedTopupOffer.id
        ) {
          return false
        } else {
          return false
        }
      }
    },
    // allowing negative values both in add & deduct balance
    getAdjustAmountForSubmission() {
      if (this.isSubscribedRidingTimeBalance && !this.selectedTopupOffer.id) {
        if (this.data.type === 'deduct' && this.adjustmentBalance > 0) {
          return `-${parseFloat(
            this.adjustmentBalance * this.ridingTimeUnitCost
          ).toFixed(2)}`
        }
        return parseFloat(
          this.adjustmentBalance * this.ridingTimeUnitCost
        ).toFixed(2)
      } else {
        if (this.data.type === 'deduct' && this.adjustmentBalance > 0) {
          return `-${this.adjustmentBalance}`
        }
        return this.adjustmentBalance
      }
    },
    getAdjustAmountForToast() {
      if (this.isSubscribedRidingTimeBalance && !this.selectedTopupOffer.id) {
        return `${this.adjustmentBalance} minutes`
      } else {
        const sign =
          this.data.type === 'deduct' && this.adjustmentBalance > 0 ? '-' : ''

        // remove the sign
        const amount = this.adjustmentBalance.toString().replace(/^-/g, '')

        return `${sign}${this.getCurrencySymbol}${amount}`
      }
    },

    getAmountPlaceholder() {
      if (this.isSubscribedRidingTimeBalance) {
        return `0 min`
      } else {
        const selected = this.wallets.find(
          (wallet) => wallet.id === this.targetAccount
        )
        if (selected) {
          return `${selected.currency.symbol} 0.00`
        }
        return this.currencySymbol + ' 0.00'
      }
    },
    getFleetId() {
      return this.$store.getters['riderdetails/getData']?.profile?.nearby_fleet
        ?.id
    },
  },
  watch: {
    getFleetId: {
      immediate: true,
      deep: false,
      handler(id) {
        if (id) {
          this.$http
            .get(
              `${TopupOfferConfig.api.index}?dropdown&fleet=${this.getFleetId}&is_active=true`
            )
            .then((res) => {
              this.topupOfferData = res.data.data
            })
        }
      },
    },
    targetAccount: {
      immediate: true,
      deep: false,
      handler(targetAccount) {
        if (targetAccount) {
          this.ridingTimeUnitCost = null
          this.ridingTimeUnitCostOptions = []
          const selectedWallet = this.wallets.find(
            (wallet) => wallet.id === this.targetAccount
          )

          const filteredFleetForRidingTimeUnitCost = selectedWallet.fleets.filter(
            (fleet) => !!fleet.amount_per_riding_time_unit
          )
          this.ridingTimeUnitCostOptions = filteredFleetForRidingTimeUnitCost.map(
            (rtuc) => {
              return {
                text: `${
                  selectedWallet.currency.symbol
                }${formatNumberWithMaxDecimals(
                  rtuc.riding_time_unit === 1
                    ? rtuc.amount_per_riding_time_unit
                    : rtuc.amount_per_riding_time_unit * 60,
                  4
                )}/min (Fleet: ${rtuc.name})`,
                value: formatNumberWithMaxDecimals(
                  rtuc.riding_time_unit === 1
                    ? rtuc.amount_per_riding_time_unit
                    : rtuc.amount_per_riding_time_unit * 60,
                  4
                ),
              }
            }
          )
        }
      },
    },
  },
  async mounted() {
    EventBus.$on(this.$config.riderUser.events.adjustBalancePop, async () => {
      this.adjustmentBalance = null
      this.handleSelectedTopupOfferInit()
      setTimeout(() => {
        if (this.$refs.adjustBalanceInput) {
          this.$refs.adjustBalanceInput.focus()
          const def = this.wallets.find((wallet) => wallet.default === true)
          if (def) {
            this.targetAccount = def.id
          }
        }
      }, 500)
    })
  },
  methods: {
    formatNumberWithMaxDecimals,
    handleSelectedTopupOfferInit() {
      this.selectedTopupOffer = {
        id: null,
        index: null,
        price: null,
        currencySymbol: null,
      }
    },
    handleTopupOfferItemSelected(item, index) {
      this.selectedTopupOffer.id = item.id
      this.selectedTopupOffer.index = index
      this.selectedTopupOffer.price = item.price
      this.selectedTopupOffer.currencySymbol = item.currency.symbol
      this.adjustmentBalance = parseInt(item.price)
    },
    submitForm() {
      if (this.isSubscribedRidingTimeBalance) {
        if (!this.adjustmentBalance && this.$refs.adjustBalanceInput) {
          this.$refs.adjustBalanceInput.focus()
          return
        }
        if (this.getRidingUnitCostViewStatus && !this.ridingTimeUnitCost) {
          this.$notify(
            {
              group: 'generic',
              type: 'default',
              title: 'Info',
              text: 'Please select an amount from Riding Time unit cost.',
            },
            3000
          )
          if (this.$refs.ridingTimeUnitCostInput) {
            this.$refs.ridingTimeUnitCostInput.focus()
          }
          return
        }
      } else {
        if (!this.adjustmentBalance && this.$refs.adjustBalanceInput) {
          this.$refs.adjustBalanceInput.focus()
          return
        }
      }
      let toastTitle
      let toastText

      if (this.selectedTopupOffer.id) {
        toastTitle = 'Topup Offer'
        toastText = `${this.getAdjustAmountForToast} Topup Offer has purchased`
      } else {
        toastTitle =
          this.data.type === 'add' ? 'Balance Added' : 'Balance Deducted'
        toastText =
          this.data.type === 'add'
            ? `${this.getAdjustAmountForToast} is added to ${this.data.fullName}'s account`
            : `${this.getAdjustAmountForToast} is deducted from ${this.data.fullName}'s account`
      }

      const formData = new FormData()
      formData.append('balance_adjustment', this.getAdjustAmountForSubmission)
      formData.append('target_account', this.targetAccount)
      formData.append('remarks', this.remarks)
      if (this.selectedTopupOffer.id) {
        formData.append('topup_offer', this.selectedTopupOffer.id)
      }

      const endpoint = this.$config.riderUser.api.balanceAdjustment(
        this.data.userId
      )

      this.isLoading = true
      this.$http
        .post(endpoint, formData)
        .then((res) => {
          // todo: emit event
          // console.log(res)
          const adjustedBalance = res.data.data.balance_after
          this.$emit('adjustedBalance', adjustedBalance)

          this.adjustmentBalance = null
          this.remarksUi = false
          this.remarks = ''

          this.$refs.popup.hide()

          this.$notify({
            group: 'generic',
            type: 'success',
            title: toastTitle,
            text: toastText,
          })
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
