export const TopupOfferConfig = {
  api: {
    // get
    index: '/dashboard/top-up-offers/',
    // post
    create: '/dashboard/top-up-offers/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/top-up-offers/${id}/`
    },
    //get
    detail: (id = 'uuid') => {
      return `/dashboard/top-up-offers/${id}/`
    },
    //delete
    delete: (id = 'uuid') => {
      return `/dashboard/top-up-offers/${id}/`
    },
  },
  events: {
    name: 'topup-offer',
    // refresh-index-data
    refresh: `rid-topup-offer`,
    // slideover-right
    sorId: 'topup-offer',
    sorOpen: 'sor-open-topup-offer',
    sorClose: 'sor-close-topup-offer',
    sorToggle: 'sor-toggle-topup-offer',
    // editing-data
    editingData: 'edit-topup-offer-data',
    // viewing-data
    viewingData: 'view-topup-offer-data',
  },
}
